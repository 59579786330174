<template>
  <div :class="$style['progeress-control-container']">
    <div :class="$style['progress-top']">
      <LayoutCard :class="$style.card" :showHeader="false">
        <ProgressControlPanel
          :configData="progressControlPanelData"
          v-if="progressControlPanelData.length"
        />
        <EmptyContent v-else />
      </LayoutCard>
    </div>
    <div :class="$style['progress-main']">
      <LayoutCard :class="$style.card" title="进度计划">
        <div class="h-full overflow-hidden">
          <ScrollTable
            :columns="scrollTbaleColumns"
            :data="scrollTbaleData"
            :infiniteScroll="false"
            v-if="scrollTbaleData.length"
          ></ScrollTable>
          <div v-else style="height:90%;width: 1rem;margin: 0 auto;">
            <NoImg />
          </div>
        </div>
      </LayoutCard>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import { LayoutCard } from '../../layout';
import ProgressControlPanel from '@/views/screen/project/people-management/components/base-worker-items';
import { getProjectProgress } from '@/services/screen/jiangsu/progress-mana';
import NoImg from '../people-management/components/noImg.vue';
@Component({
  components: {
    ScrollTable,
    LayoutCard,
    ProgressControlPanel,
    NoImg,
  },
})
export default class ProgressControlIndex extends Vue {
  scrollTbaleData = [];
  scrollTbaleColumns = [
    {
      title: '序号',
      align: 'center',
      key: 'index',
    },
    {
      title: '进度名称',
      key: 'stage',
      align: 'center',
    },
    {
      title: '工期（天）',
      key: 'duration',
    },
    {
      title: '开工日期',
      key: 'startDate',
      format: t => this.formatTime(t),
    },
    {
      title: '完工日期',
      key: 'completionDate',
      format: t => this.formatTime(t),
    },
    {
      title: '实际开工日期',
      key: 'actualStartDate',
      format: t => this.formatTime(t),
    },
    {
      title: '实际完工日期',
      key: 'actualCompletionDate',
      format: t => this.formatTime(t),
    },
    {
      title: '实际工期（天）',
      key: 'actualDuration',
      format: t => (t === -1 ? '-' : t),
    },
    {
      title: '状态',
      key: 'state',
    },
  ];

  //总任务分析
  get progressControlPanelData() {
    return [
      {
        title: '总任务工期（天）',
        value: this.topDataObj.projectDuration || '-',
      },
      {
        title: '累计施工天数（天）',
        value: this.topDataObj.projectConstructionDays || '-',
      },
      {
        title: '开工日期',
        value: this.formatTime(this.topDataObj.startDate),
      },
      {
        title: '竣工日期',
        value: this.formatTime(this.topDataObj.completionDate),
      },
    ];
  }
  created() {
    this.init();
  }
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  params = {
    current: 1,
    projectId: this.projectId,
    size: 100,
  };
  topDataObj = {};
  async init() {
    const res = await getProjectProgress(this.params);
    for (const key in res) {
      if (key !== 'projectProcesses') {
        this.topDataObj = res[key];
      }
    }
    this.scrollTbaleData = res[0]?.projectProcesses.map((ele, i) => {
      ele.index = i + 1;
      return ele;
    });
  }

  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD') : '-';
  }
}
</script>

<style lang="less" module>
.progeress-control-container {
  height: 100%;
  .progress-top {
    height: 2rem;
  }
  .progress-main {
    margin-top: 0.1rem;
    height: calc(100% - 2.1rem);
    .card {
      // background: linear-gradient(
      //   270deg,
      //   rgba(76, 175, 255, 0) 0%,
      //   rgba(76, 175, 255, 0.08) 52%,
      //   rgba(76, 175, 255, 0) 100%
      // );
    }
  }
}
</style>
